<template>
    <div>
        <div style="height:52px">
            <el-button class="btnSearch" size="small" type="primary" @click="fixPlateNo()" icon="el-icon-setting">编码更正</el-button>
            <el-button class="btnSearch" size="small" type="primary" @click="changeCondition()" icon="el-icon-search">搜索</el-button>
            <!-- <el-input v-model="deviceNoTemp" placeholder="例如: en01" class="inputSearch" @keyup.enter.native="doSearch"></el-input> -->
            <el-select size="small" class="btnSearch" v-model="queryForm.equipNo" @change="changeCondition()" filterable clearable placeholder="请选择设备">
                <el-option
                    v-for="item in queryEquipList"
                    :key="item.id" :label="item.equipNo" :value="item.equipNo">
                </el-option>
            </el-select>
            <!-- <el-select size="small" class="btnSearch" v-model="queryForm.queryLabel" placeholder="">
              <el-option
                v-for="item in queryLabelList"
                :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select> -->
            <div class="btnSearch">
                <el-date-picker size="small" v-model="startTime" @change="changeConditionTime()"
                                type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss" :default-value="new Date()"
                                :picker-options="pickerOptions">
                </el-date-picker>
            </div>
        </div>

        <div style="height:20px; margin:8px 0">
      <span style="font-size:16px;margin-left:15px;">
        <b>检测设备编号</b>:  {{queryForm.equipNo}}
        <b class="fontLabel">检测总次数</b>:  {{data.totalDetectNum}}
        <b class="fontLabel">合格铜板数</b>:  {{data.qualifiedCopperNum}}
        <b class="fontLabel">不合格铜板数</b>:  {{data.unqualifiedCopperNum}}
        <b class="fontLabel">时间</b>:  {{startTime | formatTimer}}
      </span>
        </div>

        <el-table ref="multipleTable" :data="data.copperParticleCheckRecordArrayList" tooltip-effect="dark" style="width: 100%; margin-bottom: 10px" :height="tableHeight">
            <!-- <el-table-column prop="deviceNo" label="检测设备编号"> </el-table-column> -->
            <el-table-column prop="productCode" label="阴极板编号"></el-table-column>
            <el-table-column prop="type" label="铜粒子类型"></el-table-column>
            <el-table-column prop="datetime" label="检测时间" :formatter="r => formatTime(r.datetime)"></el-table-column>
            <el-table-column prop="aArea" label="A面铜粒子面积"></el-table-column>
            <el-table-column prop="bArea" label="B面铜粒子面积"></el-table-column>
            <el-table-column prop="aIsOk" label="A面合格标识"></el-table-column>
            <el-table-column prop="bIsOk" label="B面合格标识"></el-table-column>
            <el-table-column prop="aImageName" label="A面图片名"></el-table-column>
            <el-table-column prop="bImageName" label="B面图片名"></el-table-column>
        </el-table>

        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10,20,30,40]"
            :page-size="pageSize"
            layout="total,sizes,prev,pager,next,jumper"
            :total="data.totalDetectNum"
            :pager-count="5">
        </el-pagination>
        <el-dialog title="编码更正" :visible.sync="fixPlateNoDialogVisible" width="60%" :before-close="handleClose">
            <el-row>
                <el-col :span="24" :offset="0" style="height: 50px">
                    <!--                    <el-date-picker size="small" style="margin-right: 1vw"-->
                    <!--                                    v-model="dialogDatePair" @change="changeDialogDate()"-->
                    <!--                                    type="daterange"-->
                    <!--                                    format="yyyy-MM-dd"-->
                    <!--                                    value-format="yyyy-MM-dd HH:mm:ss"-->
                    <!--                                    range-separator="至"-->
                    <!--                                    start-placeholder="开始日期"-->
                    <!--                                    end-placeholder="结束日期">-->
                    <!--                    </el-date-picker>-->
                    <el-date-picker
                        v-model="dialogDate" @change="changeDialogDate()"
                        size="small"
                        type="date"
                        placeholder="请选择日期"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <el-select size="small" v-model="dialogEquipNo" @change="changeDevice()" filterable clearable placeholder="请选择设备">
                        <el-option v-for="item in queryEquipList" :key="item.id" :label="item.equipNo" :value="item.equipNo">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" :offset="0">
                    <el-table ref="multipleTable" tooltip-effect="dark" :data="fixPlateNoDialogTableData"
                              @sort-change="changeDialogTableSort"
                              style="width: 100%;" :height="dialogTableHeight" >
                        <el-table-column prop="productCode" label="阴极板编号"  show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-input
                                    v-model="scope.row.productCode">
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="aIsOk" label="A面" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="bIsOk" label="B面" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="type" label="粒子类型" :formatter="etFormatter" show-overflow-tooltip></el-table-column>
                        <!--暂时采用默认排序-->
                        <el-table-column prop="datetime" label="创建时间" sortable=""
                                         :formatter="dateTimeFormatter" show-overflow-tooltip></el-table-column>
<!--                        <el-table-column prop="datetime" label="创建时间"-->
<!--                                         :formatter="dateTimeFormatter" show-overflow-tooltip></el-table-column>-->

                    </el-table>
                    <el-pagination
                        @size-change="handleDialogSizeChange"
                        @current-change="handleDialogCurrentChange"
                        :current-page="dialogCurrentPage"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="10"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="Number(dialogTotalCount)">
                    </el-pagination>
                </el-col>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="updateFixedPlateNo()">提 交</el-button>
                <el-button @click="fixPlateNoDialogVisible = false;">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import {fDataInfoFormatter, fDateFormat, fDateTimeFormat} from "@/utils/utilsset";
import {sanyouAxios} from "@/api";

export default {
    name: "copperdatamanage",
    data() {
        return {
            currentUserId: "",
            tableHeight: 0,
            // deviceNo: "en01",
            // deviceNoTemp: "en01",
            //查询条件
            // queryForm: {
            //   query: "",
            //   queryLabel: "deviceNo",
            // },
            // queryLabelList: [
            // {
            // value: "productNo",
            // label: "产品编码"
            // },
            //   {
            //     value: "deviceNo",
            //     label: "机器编码"
            //   }
            // ],
            //选择设备
            queryEquipList:[],
            queryForm:{
                equipNo:"",
            },
            // 日期
            startTime: "",
            // 表格数据
            data: {
                totalDetectNum: 0,
                qualifiedCopperNum: 0,
                unqualifiedCopperNum: 0,
                pageCount: 0,
                copperParticleCheckRecordArrayList: [],
            },

            //分页
            currentPage: 1,
            pageSize: 20,

            // 时间选择器判断条件
            pickerOptions: {
                disabledDate(time) {
                    let times = Date.now();
                    return time.getTime() > times;
                }
            },
            // 编码更正
            fixPlateNoDialogVisible:false,
            //编码更正-数据
            fixPlateNoDialogTableData:[],
            //编码更正-分页
            dialogPageSize:10,
            dialogCurrentPage:1,
            dialogTotalCount:0,
            dialogTableHeight:400,
            //编码更正-传入参数
            dialogDate:'',
            dialogDatePair:[],
            dialogStartTime:'',
            dialogEndTime:'',
            dialogEquipNo:'',
            //编码更正-排序
            sortMethod:'DESC',
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el['offsetTop'] - 153;
            window.onresize = () => {
                this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el['offsetTop'] - 153;
            };
            //加载时间
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth();
            //let month = Number(date.getDate())!==1?date.getMonth():date.getMonth()-1;
            let day = date.getDate();
            // this.dialogStartTime = fDateTimeFormat(new Date(year,Number(date.getDate())!==1?month:month-1,1));
            // this.dialogEndTime = fDateTimeFormat(new Date(year,month,day));
            // this.dialogDatePair=[]
            // this.dialogDatePair.push(new Date(year,Number(date.getDate())!==1?month:month-1,1));
            // this.dialogDatePair.push(new Date(year,month,day));
            this.dialogDate=fDateFormat(new Date(year,month,day));
            this.dialogStartTime=this.dialogDate+' 00:00:00'
            this.dialogEndTime=this.dialogDate+' 23:59:59'
        });
    },
    filters: {
        formatTimer: function(value) {
            let date = new Date(value);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "-" + MM + "-" + d;
        }
    },
    methods: {
        //渲染table(数据字典)
        etFormatter(row){
            return fDataInfoFormatter('PARTICLE_TYPE', row, 'type');
        },
        //格式化时间
        dateTimeFormatter(row, column, cellValue){
            return fDateTimeFormat(cellValue)
        },
        formatTime(val) {
            if (!val) {
                return ''
            }
            return moment(val).format('HH:mm:ss')
        },
        //加载数据
        loadData(){
            sanyouAxios({
                method: 'POST',
                url:'/copper/data?page=' + this.currentPage + '&size=' + this.pageSize,
                data:{
                    deviceNo: this.queryForm.equipNo,
                    startTime: this.startTime
                }
            })
                .then(res => {
                    if(res.data.status === 200){
                        this.data = res.data.data
                    }else{
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: '没有权限查看!'
                        });
                    }
                });
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.loadData()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.loadData()
        },
        changeConditionTime() {
            this.pageSize = 10
            this.currentPage = 1
            this.loadData()
        },
        changeCondition() {
            // this.deviceNo = this.deviceNoTemp
            this.pageSize = 10
            this.currentPage = 1
            this.loadData()
        },
        //获取用户所拥有权限的设备信息
        loadEquipList(){
            sanyouAxios({method: 'GET',
                url:"/equipment/getUserEquip?userId=" + this.currentUserId+"&&equipType=1"
            })
                .then(res=>{
                    if(res.data.status === 200){
                        this.queryEquipList = res.data.data;
                        if(this.queryEquipList.length > 0){
                            this.queryForm.equipNo = this.queryEquipList[0].equipNo;
                            this.dialogEquipNo = this.queryEquipList[0].equipNo;
                        }
                        this.loadData()
                    }else{
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: '获取设备信息失败!'
                        });
                    }
                });
        },
        // 加载错误编码
        loadZeroPlateNo(){
            // /copper/copperParticleDataWithoutProductNo?equipNo=en02&startTime=2023-08-01 00:00:00&endTime=2023-08-24 00:00:00
            sanyouAxios({
                //headers:{
                //    userId:this.currentUserId
                //},
                method: 'GET',
                url: '/copper/copperParticleDataWithoutProductNo',
                params: {
                    equipNo: this.dialogEquipNo,
                    startTime:this.dialogStartTime,
                    endTime:this.dialogEndTime,
                    size:this.dialogPageSize,
                    page:this.dialogCurrentPage,
                    //排序参数
                }
            }).then(res => {
                if(res.data.status === 200){
                    //弹窗
                    //console.log(res.data)
                    let data = res.data.data || [];
                    this.fixPlateNoDialogVisible=true;

                    //console.log(data);
                    this.fixPlateNoDialogTableData=data.rows;
                    this.dialogTotalCount = data.records;
                }else{
                    this.$message({
                        type: 'warning',
                        duration: 2000,
                        message: res.data.msg,
                    });
                }
            });
        },
        //加载编码更正弹窗
        fixPlateNo(){
            //加载弹窗内容
            this.loadZeroPlateNo()
        },
        //批量更新
        updateFixedPlateNo(){
            //过滤
            let fixedData=this.fixPlateNoDialogTableData.filter((item)=>{
                return Number(item['productCode']) !== 0
            })
            //console.log(fixedData)

            sanyouAxios({
                // headers:{
                //     userId:this.currentUserId
                // },
                method: 'POST',
                url: '/copper/updateProductNoOfCopperParticleRecords',
                data: fixedData
            }).then(res => {
                if(res.data.status === 200){
                    //提示
                    this.$message({
                        duration: 2000,
                        message: res.data.data,
                        type: "success",
                    });
                    //关闭弹窗
                    this.fixPlateNoDialogVisible=false;
                }else{
                    this.$message({
                        type: 'warning',
                        duration: 2000,
                        message: res.data.msg,
                    });
                }
            });
        },
        //监听日期选择器更变
        changeDialogDate(){
            // if(this.dialogDatePair != null && this.dialogDatePair.length > 0){
            //     this.dialogStartTime=this.dialogDatePair[0];
            //     this.dialogEndTime=this.dialogDatePair[1];
            //
            //     this.loadZeroPlateNo();
            // }
            if (this.dialogDate){
                //2023-08-01 00:00:00
                this.dialogStartTime=this.dialogDate+' 00:00:00'
                this.dialogEndTime=this.dialogDate+' 23:59:59'
                this.loadZeroPlateNo();
            }
        },
        changeDevice() {
            this.loadZeroPlateNo();
        },
        // 弹窗Table排序
        changeDialogTableSort(column) {
            console.log("column", column);
            // this.fieldName = column.prop;
            let sortingType = column.order;
            sortingType === "ascending"
                ? (this.sortMethod = "ASC")
                : (this.sortMethod = "DESC");
            //this.loadZeroPlateNo();
        },

        //每页显示数据量变更
        handleDialogSizeChange: function (val) {
            this.dialogPageSize = val;
            let currentPage = this.dialogCurrentPage;

            this.loadZeroPlateNo(currentPage,val);
        },
        //页码变更
        handleDialogCurrentChange: function (val) {
            this.dialogCurrentPage = val;
            let pageSize = this.dialogPageSize;
            this.loadZeroPlateNo(val,pageSize);
        },
        //编码更正的关闭逻辑
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        }
    },
    created() {
        this.currentUserId = localStorage.getItem('id');
        this.startTime =  moment(new Date()).format('YYYY-MM-DD 00:00:00');
        this.loadEquipList()
    }
};
</script>
<style scoped>
.btnDelete {
    margin: 10px 10px;
    float: left;
}
.inputSearch {
    float: right;
    width: 200px;
    padding-right: 10px;
    padding-top: 5px;
}
.btnSearch {
    margin: 10px 10px;
    float: right;
}
.fontLabel{
    margin-left: 10px;
}

</style>
